<template>
    <section  style="background: #FFFFFF">
        <!--组织展示-->
        <el-col :span="3">
             <div class="orgbtndivtree">
                <el-divider>人员</el-divider>
                <el-checkbox @change="ckbchange1" v-model="checked1" size="mini" label="一线" border></el-checkbox>
                <el-checkbox @change="ckbchange2" v-model="checked2" style="margin-left:-10px;" size="mini" label="后勤" border></el-checkbox>
                <el-cascader :props="props" v-model="cascadervalue" ref="department" clearable size="mini" :show-all-levels="false" expand-trigger="hover" popper-class="depc" @change="cascaderchange"></el-cascader>
               <div>
                    <el-select size="mini" v-model="myvalue" remote filterable multiple :remote-method="FindUser" placeholder="输入姓名筛选">
                        <el-option
                            v-for="item in myoptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
               </div>
                
            </div>
            <div class="orgtree" style="padding-top:19px;">
             <!--中间列表-->
                <el-table
                    :data="departusers"
                    highlight-current-row
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    @current-change="selectDepartUserRow"
                    >
                    <el-table-column type="index" width="70px"></el-table-column>
                    <el-table-column label="姓名" prop="name" align="left"></el-table-column>
                    <!-- <el-table-column label="部门" prop="DepartmentName" align="center"></el-table-column> -->
                </el-table>
             <!-- 分页器 -->
            <el-pagination align='center' small
                       :pager-count="5" @current-change="handleCurrentChangeDepart" :current-page="DepartcurrentPage" :page-size="DepartpageSize" layout="prev, pager, next" :page-count="Departtotal" :total="Departtotaldata">
            </el-pagination>
         </div>
        </el-col>
        <!--工作数据展示-->
        <el-col :span="21">
            <div>
                <el-divider>年度计划任务</el-divider>
                <el-form :inline="true" style="width: 100%;overflow-x: auto;white-space: nowrap;" size="mini">
                    <el-form-item label="所属年份">
                    <el-select v-model="yearvalue" style="width:125px" placeholder="请选择所属年份">
                        <el-option
                            v-for="item in yearoptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="工作项目:">
                        <el-input v-model="workItem" placeholder="输入工作项目查询" maxlength="10" show-word-limit clearable  style="width: 100%;"></el-input>
                    </el-form-item>
                    <el-form-item style="margin-left: 0px;">
                        <el-button size="mini" type="normal" round icon="el-icon-search" @click="getManagerChecks">查询</el-button>
                    </el-form-item> 
                </el-form>
                
                
              <!--列表-->
                <el-table
                    :data="users" 
                    highlight-current-row
                    row-key = "Id"
                    lazy
                    :indent="30"
                    :load = "load"
                    :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                    :fit="true"
                    :row-style="{height:'40px'}"
                    :cell-style="{padding:'0px'}"
                    :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                    :row-class-name="tableRowClassName"
                    @current-change="selectCurrentRow"
                    @row-dblclick="openPinggu"
                    style="width: 100%">
                        <el-table-column type = "index"></el-table-column>
                        <el-table-column label="工作项目" prop="WorkItem" min-width="150px" >
                            <template slot-scope = "scope">
                                <span :style="{'cursor':'default'}">{{scope.row.WorkItem}}</span>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column label="工作描述" prop="WorkDescription" min-width="170px" ></el-table-column> -->
                        <el-table-column label="项目类型" prop="WorkType" align="center">
                            <template slot-scope = "scope">
                                <div v-if="scope.row.WorkType==1">日常
                                </div>
                                <div v-else-if="scope.row.WorkType==2">项目
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="计划完成时间" prop="PlanComplateTime" min-width="110px" :formatter="formatPlanComplateTime">
                            <template slot-scope = "scope">
                                <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                                    <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
                                </div>
                                <div v-else>
                                    <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="权重" min-width="75" prop="Rate">
                            <template slot-scope = "scope">
                                <el-tag v-if="scope.row.Rate == null">
                                    0
                                </el-tag>
                                <el-tag v-else>{{scope.row.Rate}}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="所属年份" prop="OfYear" min-width="80px" :formatter="formatOfYear"></el-table-column>
                        <!-- <el-table-column label="责任人" min-width="70px" prop="PersonOfDuty"></el-table-column> -->
                        <el-table-column label="任务状态" prop="Status" align="center" min-width="100px">
                            <template slot-scope = "scope">
                                <el-tooltip placement="top">
                                    <!-- <div slot="content">
                                        已完成{{  scope.row.TaskProgressValue }}%
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                                        审核中
                                    </div> -->
                                    <div slot="content" v-if="scope.row.Status == 0 || scope.row.Status == 2">
                                        已完成{{  scope.row.TaskProgressValue }}%
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                                        审核中
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == 6 || scope.row.Status == 3">
                                        已退回
                                    </div>
                                    <div slot="content" v-if="scope.row.Status == -1">
                                        已取消
                                    </div>
                                    <div>
                                        <el-tag v-if="scope.row.Status == 0" effect="dark"> 进行中</el-tag>
                                        <el-tag v-if="scope.row.Status == 2" effect="dark" type="success">已完成</el-tag>
                                        <el-tag v-if="scope.row.Status == -1" effect="dark" type="info">已取消</el-tag>
                                        <el-tag v-if="scope.row.Status == 3" effect="dark" type="danger">退回</el-tag>
                                        <el-tag v-if="scope.row.Status == 1" effect="dark" type="warning">已完成审核中</el-tag>
                                        <el-tag v-if="scope.row.Status == 4" effect="dark" type="warning">新建任务待审核</el-tag>
                                        <el-tag v-if="scope.row.Status == 5" effect="dark" type="danger">取消任务待审核</el-tag>
                                        <el-tag v-if="scope.row.Status == 6" effect="dark" type="danger">新建任务退回</el-tag>
                                        <el-tag v-if="scope.row.Status == 8" effect="dark" type="warning">阅知审核中</el-tag>
                                        <el-tag v-if="scope.row.Status == 9" effect="dark" type="success">审核完成</el-tag>
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="创建时间" prop="CreateTime" min-width="100px" :formatter = "formatCreateTime">
                            <template slot-scope="scope">
                                <el-tooltip placement="left">
                                    <div slot="content">
                                        {{ formatTipCreateTime( scope.row) }}
                                    </div>
                                    <div>
                                        {{ formatCreateTime( scope.row) }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120px;">
                            <template slot-scope="scope">
                                <el-button type="text" size="medium" @click="openPinggu(scope.row)">评估</el-button>
                                <el-button type="text" size="medium" @click="QueryMyPingfenList(scope.row)">评分记录</el-button>
                                <!-- <el-tooltip  placement="top" >
                                    <el-button type="text" size="medium" @click="checkInfo(scope.row)">评估</el-button>
                                </el-tooltip> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="最近评分" prop="LastPingfen" min-width="80px" align="center" >
                            <template slot-scope="scope">
                                <p style="font-size:9px" v-show="scope.row.LastPingfenMonth"><el-tag size="mini">{{scope.row.LastPingfenScore+'分'}}</el-tag></p>
                                <p style="font-size:9px" v-show="scope.row.LastPingfenMonth">{{scope.row.LastPingfenMonth}}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页器 -->
                    <el-pagination align='center' @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next, jumper" :page-count="total" :total="totaldata">
                    </el-pagination>
                                        
                    <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="yearplan" ></taskinfo>

                <el-dialog
                :visible.sync="pingguVisible" 
                v-model="pingguVisible"
                :close-on-press-escape="false"
                :close-on-click-modal="false"
                @close="pingguclose"
                width="1000px"  v-dialogDrag
                center @opened="handlePingfenOpened">
                <template slot="title">
                    <span style="color:#FFFFFF;font-size: 16px;">年度计划绩效评估</span>
                </template>
                    <el-form>
                        <el-row>
                            <el-col>
                              <p v-if="calcRate>0" style="display:flex;font-size:13px;">
                                        <span >{{addForm.Month}}月完成率：</span>
                                        <el-progress style="width:80%;margin-top:0px;margin-left:16px;margin-bottom:10px;" :percentage="calcRate" ></el-progress>
                                    </p>
                            </el-col>
                        </el-row>
                        <el-table
                            :data="singleYearplanTask" 
                            highlight-current-row
                            row-key = "Id"
                            lazy
                            :indent="30"
                            :load = "load"
                            :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                            :fit="true"
                            :row-style="{height:'40px'}"
                            :cell-style="{padding:'0px'}"
                            :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                            :row-class-name="tableRowClassName"
                            @current-change="selectCurrentRow"
                            @row-dblclick="checkInfo"
                            style="width: 100%">
                            <el-table-column type = "index"></el-table-column>
                            <el-table-column label="工作项目" prop="WorkItem" min-width="150px" show-overflow-tooltip></el-table-column>
                            <!-- <el-table-column label="工作描述" prop="WorkDescription" min-width="170px" ></el-table-column> -->
                            <el-table-column label="计划完成时间" prop="PlanComplateTime" min-width="110px" :formatter="formatPlanComplateTime">
                                <template slot-scope = "scope">
                                    <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                                        <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
                                    </div>
                                    <div v-else>
                                        <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="权重" min-width="55" prop="Rate">
                                <template slot-scope = "scope">
                                    <el-tag v-if="scope.row.Rate == null">
                                        0
                                    </el-tag>
                                    <el-tag v-else>{{scope.row.Rate}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="所属年份" prop="OfYear" min-width="50px" :formatter="formatOfYear"></el-table-column>
                            <!-- <el-table-column label="责任人" min-width="70px" prop="PersonOfDuty"></el-table-column> -->
                            <el-table-column label="任务状态" prop="Status" align="center" min-width="70px">
                                    <template slot-scope = "scope">
                                        <el-tooltip placement="left" effect="light">
                                            <!-- <div slot="content">
                                                已完成{{  scope.row.TaskProgressValue }}%
                                            </div>
                                            <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                                                审核中
                                            </div> -->
                                            <div slot="content" v-if="scope.row.Status == 0 || scope.row.Status == 2">
                                                已完成{{  scope.row.TaskProgressValue }}%
                                            </div>
                                            <div slot="content" v-if="scope.row.Status == 1 || scope.row.Status == 4 || scope.row.Status == 5">
                                                审核中
                                            </div>
                                            <div slot="content" v-if="scope.row.Status == 6 || scope.row.Status == 3">
                                                已退回
                                            </div>
                                            <div slot="content" v-if="scope.row.Status == -1">
                                                已取消
                                            </div>
                                            <div>
                                                <el-tag v-if="scope.row.Status == 0" effect="dark"> 进行中</el-tag>
                                                <el-tag v-if="scope.row.Status == 2" effect="dark" type="success">已完成</el-tag>
                                                <el-tag v-if="scope.row.Status == -1" effect="dark" type="info">已取消</el-tag>
                                                <el-tag v-if="scope.row.Status == 3" effect="dark" type="danger">退回</el-tag>
                                                <el-tag v-if="scope.row.Status == 1" effect="dark" type="warning">已完成审核中</el-tag>
                                                <el-tag v-if="scope.row.Status == 4" effect="dark" type="warning">新建任务待审核</el-tag>
                                                <el-tag v-if="scope.row.Status == 5" effect="dark" type="danger">取消任务待审核</el-tag>
                                                <el-tag v-if="scope.row.Status == 6" effect="dark" type="danger">新建任务退回</el-tag>
                                                <el-tag v-if="scope.row.Status == 8" effect="dark" type="warning">阅知审核中</el-tag>
                                                <el-tag v-if="scope.row.Status == 9" effect="dark" type="success">审核完成</el-tag>
                                            </div>
                                        </el-tooltip>
                                    </template>
                            </el-table-column>
                            <!-- <el-table-column label="创建时间" prop="CreateTime" min-width="100px" :formatter = "formatCreateTime">
                                <template slot-scope="scope">
                                    <el-tooltip placement="left" effect="light">
                                        <div slot="content">
                                            {{ formatTipCreateTime( scope.row) }}
                                        </div>
                                        <div>
                                            {{ formatCreateTime( scope.row) }}
                                        </div>
                                    </el-tooltip>
                                </template>
                            </el-table-column> -->
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button type="text" size="medium" @click="checkInfo(scope.row)">查看任务</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                    
                    <el-collapse>
                        <el-collapse-item name="1">
                            <template slot="title">
                                <i class="header-icon el-icon-date" style="margin-right:2px"></i>日常任务明细
                            </template>
                            <el-form style="height:30px;">
                                <el-form-item label="任务类型:">
                                    <el-select v-model="taskClassify" size="mini" style="width: 12%;" >
                                        <el-option v-for="item in ClassifyModules" :key="item.value" :label="item.label" :value="item.value"
                                        ></el-option>
                                    </el-select>&nbsp;
                                    任务创建时间所属月份：<el-date-picker style="width:14%;" :editable="false" placeholder = "选择月份筛选" value-format="yyyy-MM" v-model ="queryMonth" type="month" size="mini" ></el-date-picker>
                                    任务计划完成时间所属月份：<el-date-picker style="width:14%;" :editable="false" placeholder = "选择月份筛选" value-format="yyyy-MM" v-model ="queryMonth2" type="month" size="mini" ></el-date-picker>
                                    <el-button size="mini" icon="el-icon-search" @click="handleSearchAboutTask">筛选</el-button>
                                </el-form-item>
                            </el-form>
                            <el-form  label-width="85px" style="white-space:nowrap;">
                                    <el-table
                                        :data="aboutTaskList"
                                        height="200px"
                                        row-key = "Id"
                                        lazy
                                        :indent="30"
                                        :load = "load"
                                        :tree-props = "{children: 'children',hasChildren: 'IsHasChildren'}"
                                        :row-class-name="MultitableRowClassName"
                                        @row-dblclick="checkAboutInfo"
                                        :row-style="{height:'40px'}"
                                        :cell-style="{padding:'0px'}"
                                        :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                                        :fit="true">
                                        <el-table-column type="index" min-width="50px"></el-table-column>
                                        <el-table-column label="工作项目" prop="WorkItem" min-width="280px" ></el-table-column>
                                        <el-table-column label="计划完成时间" prop="PlanComplateTime" min-width="100px" :formatter="formatPlanComplateTime" ></el-table-column>
                                        <el-table-column label="责任人" prop="PersonOfDuty" min-width="60px"></el-table-column>
                                        <el-table-column label="进度" prop="TaskProgressValue" :formatter="formatProgress" align="left" min-width="50px"></el-table-column>
                                        <el-table-column label="任务类型" prop="CommonTaskClassifyText" min-width="80px" :formatter="formatCommonTaskClassifyText"></el-table-column>
                                        <el-table-column label="创建时间" prop="CreateTime" min-width="70px" :formatter = "formatCreateTime">
                                            <template slot-scope="scope">
                                                <el-tooltip placement="left" effect="light">
                                                    <div slot="content">
                                                        {{ formatTipCreateTime( scope.row) }}
                                                    </div>
                                                    <div style="cursor:default">
                                                        {{ formatCreateTime( scope.row) }}
                                                    </div>
                                                </el-tooltip>
                                            </template>
                                        </el-table-column>
                                        <!-- <el-table-column label="任务评分" prop="Score" min-width="75px">
                                            <template slot-scope="scope">
                                            <div v-if="scope.row.Score==0"></div>
                                            <div v-else-if="scope.row.Score==1">A</div>
                                            <div v-else-if="scope.row.Score==2">B</div>
                                            <div v-else-if="scope.row.Score==3">C</div>
                                            <div v-else-if="scope.row.Score==4">D</div>
                                            </template>
                                        </el-table-column> -->
                                        <el-table-column label="操作">
                                            <template slot-scope="scope">
                                                <el-button type="text" size="medium" @click="checkAboutInfo(scope.row)">查看</el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                            </el-form>
                        </el-collapse-item>
                    </el-collapse>
                    
                    <el-form>
                        <el-row  style="margin-top:15px">
                            <el-col :span="10">
                                <el-form-item label="评估月份:" prop="Month">
                                    <el-date-picker  :editable="false" @change="handlePingguMonthChange" v-model ="addForm.Month" type = "month" value-format="yyyy-MM" placeholder = "请选择评估月份" style="left: 20px;"></el-date-picker>
                                    <!-- <p v-if="calcRate>0" style="display:flex;font-size:13px;">
                                        <span >{{addForm.Month}}月完成率：</span>
                                        <el-progress style="width:250px;margin-top:10px;margin-left:16px" :percentage="calcRate" ></el-progress>
                                    </p>
                                     -->
                                </el-form-item>

                            </el-col>
                            <el-col :span="14">
                                <el-row>
                                    <el-col :span="24">
                                        <el-form-item label="分值:" prop="Score">
                                            <!-- <el-input v-model="addForm.PersonOfDuty" readonly >{{dutyneedname}}</el-input> -->
                                            <el-input-number :disabled="addForm.Status==1" v-model="addForm.Score" :min="-50" :max="100" :precision="1" ></el-input-number>
                                            <span style="border:0px red solid;font-size:9px;color:#999;margin-left:5px;">评估分值可参考任务权重（0~权重值之间），但非必须。</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-form>
                    <el-form>
                        <el-form-item label="评价:" prop="Content">
                            <el-input :readonly="addForm.Status==1" style="width:95%" type="textarea" :autosize="{ minRows: 3, maxRows: 4}" placeholder="请输入评价内容" v-model="addForm.Content"></el-input>
                            <div style="margin-left:42px;height:80px">
                                <div @click="handleAlertItemClick(text)" v-if="alerts" v-for="text in alerts">
                                    <el-alert style="cursor:pointer" :title="text" type="info"></el-alert>
                                </div>
                                <!-- <el-alert bi @click="handleAlertItemClick()" v-if="alerts" v-for="text in alerts" style="cursor:pointer" :title="text" type="info" ></el-alert> -->
                                <!-- <el-alert :title="'请在当月号前完成绩效评分，超期即自动锁定无法评分'" type="warning" ></el-alert> -->
                            </div>
                        </el-form-item>
                    </el-form>
                    <el-form>
                        <el-form-item label="状态:" prop="Status">
                            <div style="font-weight: bold;" v-if="addForm.Status==1" >已提交</div>
                            <div style="font-weight: bold;" v-else-if="addForm.Status==0" >已保存</div>
                            <div style="font-weight: bold;" v-else-if="addForm.Status==-1" ></div>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button :disabled="disabledSave" v-show="showSaveBtn" @click="addSave">保存</el-button>
                        <el-button :disabled="disabledSubmit" v-show="showSubmitBtn" type="goon" @click="addSubmit">提交</el-button>
                        <el-button type="warning" @click="handleCloseAboutTaskList">关闭</el-button>
                        <el-alert style="margin-top:10px;" :title="'请在当月'+maxDay+'号前完成绩效评分，超期即自动锁定无法评分'" center type="warning" ></el-alert>
                    </div>
                </el-dialog>

                <el-dialog
                :visible.sync="mypingguListVisible" 
                v-model="mypingguListVisible"
                :close-on-press-escape="false"
                :close-on-click-modal="false" 
                width="1000px"  v-dialogDrag
                center >
                <template slot="title">
                    <span style="color:#FFFFFF;font-size: 16px;">年度任务评分记录</span>
                </template>
                    <el-form>
                        <el-table
                            :data="myPingfenList" 
                            row-key = "Id"
                            :indent="30"
                            :fit="true"
                            :show-summary="true"
                            :summary-method="getSummaries"
                            :row-style="{height:'40px'}"
                            :cell-style="{padding:'0px'}"
                            :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                            :row-class-name="tableRowClassName"
                            style="width: 100%">
                            <el-table-column type = "index"></el-table-column>
                            <el-table-column label="评估内容" prop="Description" min-width="280px">
                                <template slot-scope = "scope">
                                    <div>{{scope.row.Description}}<i style="cursor:pointer" v-clipboard:copy="scope.row.Description" v-clipboard:success="onCopy" class="el-icon-document-copy"></i></div>
                                </template>
                            </el-table-column>
                            <el-table-column label="评估月份" min-width="55" prop="Month">
                                <template slot-scope = "scope">
                                    <el-tag >{{scope.row.Month}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="评分" prop="Score" align="center" min-width="30px" ></el-table-column>
                            <el-table-column label="评分状态" prop="Status" align="center" min-width="70px">
                                    <template slot-scope = "scope">
                                        <el-tooltip placement="left" effect="light">
                                            <div slot="content" v-if="scope.row.Status == 0">
                                                暂存，未提交
                                            </div>
                                            <div slot="content" v-if="scope.row.Status == 1">
                                                已提交
                                            </div>
                                            <div>
                                                <el-tag v-if="scope.row.Status == 0" effect="dark">已暂存</el-tag>
                                                <el-tag v-if="scope.row.Status == 1" effect="dark" type="success">已提交</el-tag>
                                            </div>
                                        </el-tooltip>
                                    </template>
                            </el-table-column>
                        </el-table>
                    </el-form>
                </el-dialog>


            </div>
        </el-col>

        <taskinfo v-if="lookOneAboutTaskVisible" :data="checkForm" @closeFunction="checkclose2" ></taskinfo>

    </section>
</template>
<script>
import util from '../../../util/date';
import {GetRemoteConfigValue,AddTaskProgressReview,QueryReviewByProgressId,AddTaskPingfen,UpdateTaskPingfen,QueryTaskPingfenByTaskId,GetChildrenList,GetManagePost,GetDepartmentList,GetDepartmentTypes,QueryPageYearplanTasksByWhere,QueryPageYearplanTasksByWhereForJixiaopinggu,GetClassifyList,QueryYearPlanAboutTaskByYearplanId,QueryTasksById,QueryTaskProgressByTaskId,QueryChildTasksByParentId,GetUserTopDepartmentList,GetPostDepartmentOrganizationViewAllByUserId,GetDepartmentPostUserList,GetYearPlanListByUserCode,QueryPeoplePingfenReport} from "../../api/oa"
import taskinfo from "../../components/taskinfo.vue"
import dialogDrag from '../../../util/dialogDrag'

export default {
    components: { taskinfo , dialogDrag },
    data(){
        return{
            calcRate:0,
            disabledSave:false,
            disabledSubmit:false,
            checked1:true,
            checked2:true,
            myvalue:'',
            myoptions:[],
            currentPage: 1, // 当前页码
            total: null, // 总条数
            totaldata:null,
            pageSize: 10, // 每页的数据条数
            page: 1,
            DepartcurrentPage: 1, // 当前页码
            Departtotal: null, // 总条数
            Departtotaldata:null,
            DepartpageSize:  10, // 每页的数据条数
            Departpage: 1,
            departmentsearchdata: [],
            TypeIdModules: [],   //部门类型集合
            users: [],
            alerts: [],
            content:'',
            mapReview:new Map(),
            singleYearplanTask: [],
            aboutTaskList:[],
            myPingfenList:[],
            departusers: [],
            departusersfull:[],
            departmentList: [],
            DptProps: {
                label: 'Name',
                isLeaf: 'Leaf'
            },
            currentRow: null,
            DepartUserRow: null,
            workItem: null,
            taskClassify: 0,
            queryMonth:'',
            queryMonth2:'',
            taskStatus: 0,
            progressValue: null,
            deptinput: null,
            madedate: null,
            cascadervalue: null,
            StatusModules: [
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "进行中"
                },
                {
                    value: 2,
                    label: "已完成"
                }
            ],
            ClassifyModules:[
                {
                    value: 0,
                    label: "全部"
                },
                {
                    value: 1,
                    label: "日常工作"
                },
                {
                    value: 3,
                    label: "例会项目"
                },
                {
                    value: 5,
                    label: "专项会议"
                },
            ],
            yearoptions:[{
                value: 2021,
                label: '2021年度'
                }, {
                value: 2022,
                label: '2022年度'
                }, {
                value: 2023,
                label: '2023年度'
                }, {
                value: 2024,
                label: '2024年度'
                }, {
                value: 2025,
                label: '2025年度'
                }, {
                value: 2026,
                label: '2026年度'
                }, {
                value: 2027,
                label: '2027年度'
                }
            ],
            yearvalue:new Date().getFullYear(),
            checkVisible: false,
            pingguVisible: false,
            mypingguListVisible: false,
            showSubmitBtn:true,
            showSaveBtn:true,
            lookOneAboutTaskVisible: false,
            checkForm: {            //查看表单初始化
                Id: null,
                WorkItem: null,
                WorkDescription: null,
                PlanComplateTime: null,
                Remark: null,
                IsNeedApproval: null
            },
            addForm: {                   //新增表单初始化
                Id:'',
                Score: 0,
                Month: '',
                Content: '',
                Status:-1
            },
            showAssign: false,
            createPerson: false,                    //创建人是否显示
            showyearplan: false,                    //年度计划查看界面显示控制
            showorganize: false,                     //跨部门协同查看界面显示控制
            showregularproject: false,               //例会来源查看界面控制
            showprofessionmeetong: false,            //专项会议查看界面控制
            CommonTaskClassifyText: null,
            pValue1: null,   //进度条数值
            istaskpro: false,
            tasks: [],
            options: [],
            checkdepartid: null,
            departuserid: null,
            props: {
                pagethis:this,
                checkStrictly: true,
                lazy: true,
                lazyLoad (node, resolve) {
                    let self=this.pagethis;
                    const { level,value } = node;
                    if(node.level == 0){
                        var user = JSON.parse(window.localStorage.user);
                        var userid="";
                        if(user.name=='马玲' || user.name=='杨光')
                        {
                            userid='b57e4ab5-7df4-482b-a8f3-a36f012b963f';
                        }
                        else if (user.name == '张刚' || user.name == '常万胜')
                        {
                            userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        }
                        // else if (user.name == '常万胜')
                        // {
                        //     userid = '33824a20-4d5e-4498-8786-a36f012b95e1';
                        // }
                        else if(user.name == '刘绪璐')
                        {
                            userid = '0b84fc71-ade2-46d9-814f-a36f012b8be1';
                        }else if(user.name == '孙成伟')
                        {
                            userid='db7992cd-88fa-4f1d-8557-a36f012b8cfa';
                        }
                        else if (user.name == '韩朝晖')//环保一、活性炭
                        {
                            // userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                            userid = '83a89dc5-a08f-4aaf-97de-a36f012b8c98';
                        }
                        else if (user.name == '付志浩')//环保科技
                        {
                            userid = '9928b2e3-61ec-4a8a-8067-a36f012b886d';
                        }
                        else{
                            userid=user.sub;
                        }
                        GetPostDepartmentOrganizationViewAllByUserId({
                            // isMainJob:1,
                            userId: userid
                        })
                        .then(res =>{
                            // console.log(res);
                            var mydata = res.data.data;
                            mydata = self.unique(mydata);
                            const nodes = mydata.map(x=>({
                                value: x.deptId,
                                label: x.deptName,
                                leaf: x.hasNode
                            }))
                            resolve(nodes)   
                        });
                        // GetUserTopDepartmentList({userId: userid}).then(res => {
                        //     const nodes = res.data.data.map(x=>({
                        //         value: x.deptId,
                        //         label: x.deptName,
                        //         leaf: !x.hasNode
                        //     }))
                        //     resolve(nodes)                         
                        // });
                    }else{
                        GetChildrenList({deptId: node.value}).then(res => {
                            // console.log(res);
                            var mydata = res.data.data;
                            if(mydata.dept[0].children!=null)
                            {
                                const nodes = res.data.data.dept[0].children.map(x=>({
                                    value: x.id,
                                    label: x.label,
                                    leaf: !x.hasChildren
                                }))
                                // console.log(nodes);
                                resolve(nodes)   
                            }else{
                                resolve([]);
                            }
                        })
                    }
                    
                }
            },
            YearPlanTask: null,
            YearPlanTaskId: null,
            maxDay:5
        }
    },
    methods:{
        calcPersonRate(){
            let self = this;
            var peoples = [];
            peoples.push(self.departuserid)
            var month = self.addForm.Month;
            var totalRate = 0;
            if(self.users){
                self.users.forEach(element=>{
                    totalRate += element.Rate;
                })
            }
            console.log('totalRate>>>>'+totalRate);
            QueryPeoplePingfenReport({peoples:peoples,month:month}).then(res=>{
                // console.log(res);
                var data = res.data.response.data;
                if(data){
                    var totalPingfen = 0;
                    data.forEach(element => {
                        // console.log(element)
                        totalPingfen += element.Score;
                    });
                    console.log('totalPingfen>>>'+totalPingfen);
                    var calcRate = totalPingfen/totalRate*100;
                    console.log('totalRate>>>'+calcRate);
                    if(calcRate>100){
                        calcRate = 100;
                    }
                    calcRate = 33.333333;
                    self.calcRate = calcRate;
                }
            });
        },
        handleAlertItemClick(text){
            console.log('handleAlertItemClick')
            let self = this;
            self.addForm.Content = self.addForm.Content + text;
        },
        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }else if(index==3)
                {
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                        }, 0);
                        sums[index] += '分';
                    } else {
                        sums[index] = 'N/A';
                    }
                }
            });

            return sums;
        },
        onCopy (e) {
            this.$message({
                message: '内容已复制',
                type: 'success'
            });
        },
        handlePingfenOpened(){
            this.GetRemoteConfigValue();
        },
        handleChange(val)
        {
        // console.log(val)
        if(val.length>0)
        {
            var progressId = val[0];
            this.loadReview(progressId);
        }
        },
        catchData(c)
        {
        // console.log(c)
        this.content=c;
        },
        handlePopoShow()
        {
        
        },
        handlePopoReplyShow()
        {

        },
        myname()
        {
        var user = JSON.parse(window.localStorage.user);
        return user.name;
        },
        handleReplyReview(progress,rid)
        {
        // console.log(progress);
        // console.log(rid);
        let self=this;
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = rid;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
            // console.log(res);
            if (res.data.success) {
            this.$message({
                message: res.data.msg,
                type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
                if(this.tasks[a].Id==progressId)
                {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
                }
            }
            this.loadReview(progressId);
            } else {
            this.$message({
                message: res.data.msg,
                type: 'error'
            });
            }
        })
        },
        handleSendReview(progress)
        {
        let self=this;
        // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
        {
            var progressId = progress.Id;
            var user = JSON.parse(window.localStorage.user);
            var para={};
            para.TaskID=this.checkForm.Id;
            para.ProgressID=progressId;
            para.ReplayID = null;
            para.ReviewContent = self.content;
            para.CreatedId = user.sub;
            para.CreatedBy = user.name;
            // console.log(para);

            AddTaskProgressReview(para).then((res) => {
            // console.log(res);
            if (res.data.success) {
                this.$message({
                message: res.data.msg,
                type: 'success'
                });
                for(var a=0;a<self.tasks.length;a++)
                {
                if(this.tasks[a].Id==progressId)
                {
                    this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                    break;
                }
                }
                this.loadReview(progressId);
            } else {
                this.$message({
                message: res.data.msg,
                type: 'error'
                });
            }
            })
        }
        // )
        },
        loadReview(progressid)
        {
        let self=this;
        // console.log(progressid)
        let para = { progressId: progressid };
        QueryReviewByProgressId(para).then((res) => {
            // console.log(res)
            var data = res.data.response;
            // console.log(data);
            self.mapReview.set(progressid,data);
            // console.log(self.mapReview);
            this.$forceUpdate()
            // for(var a=0;a<data.length;a++)
            // {
            //   self.mapReview.set(progressid,data);
            // }
        });
        },
        getReplyReview(arr,id)
        {
        // console.log(arr);
        // console.log(id);
        var o ={
            CreatedBy:'',
            CreatedId:'',
            CreatedTime:'',
            ReviewContent:''
        };
        for(var i=0;i<arr.length;i++)
        {
            if(arr[i].Id==id)
            {
            o = arr[i];
            break;
            }
        }
        return o;
        },
        handleProcessSelectChange (taskid, index) {
            this.currentActiveId = taskid;
            this.currentProgressRowIndex = index;
        },
        showTaskFile (file) {
            window.open(file, "_blank");
        },
        ckbchange1(val)
        {
           this.getDepartUsers(this.checkdepartid);
        },
        ckbchange2(val)
        {
           this.getDepartUsers(this.checkdepartid);
        },
        FindUser(query)
        {
            if (query !== '') {
              this.loading = true;
              setTimeout(() => {
                this.loading = false;

                var searchresult=[];
                this.departusersfull.forEach(element => {
                    if(element.name.indexOf(query)>=0)
                    {
                        searchresult.push(element)
                    }
                });

                this.departusers=searchresult;
                // this.departusers=this.departusers.filter(item=>{
                //     return item.UserNameClaim=query;
                // });
                // this.myoptions = this.departusers.filter(item => {
                //     //console.log(item);
                // //   return item.UserNameClaim+item.UserName.toLowerCase()
                // //     .indexOf(query.toLowerCase()) > -1;
                // });
              }, 200);
            } else {
              this.departusers = this.departusersfull;
            }
        },
        handleCloseAboutTaskList()
        {
            this.pingguVisible=false;
        },
        handleSearchAboutTask()
        {
            var yearplantaskid=this.singleYearplanTask[0].Id;
            var taskclassify=this.taskClassify;
            var querymonth=this.queryMonth;
            if(querymonth==null)
            {
                querymonth='';
            }
            var querymonth2=this.queryMonth2;
            if(querymonth2==null)
            {
                querymonth2='';
            }
            // console.log(taskclassify);
            QueryYearPlanAboutTaskByYearplanId({yearplantaskid:yearplantaskid,taskclassify:taskclassify,month:querymonth,month2:querymonth2,querySelf:true}).then(res=>{
                // console.log(res);
                this.aboutTaskList = res.data.response.data;
            });
        },
        MultitableRowClassName({row, rowIndex}){
            if (rowIndex % 2 !== 0) {
                return 'warning-row';
            }
            return '';
        },
        //组织维护加载节点的子节点集合
        loadDptChildNode (node, resolve) {
            console.log(node);
            let para = { deptId: node.data.Id };
            GetChildrenList(para).then((res) => {
                const roledata = res.data.data.dept;
                // console.log(res.data.data.dept)
                resolve(roledata);
            });
        },
           //部门树点击
        dptTreeNodeClick (node) {
            this.getManagerChecks();
        },
        //部门查询按钮
        departmentFind () {
            let para = { name: this.deptinput };
            GetDepartmentList(para).then((res) => {
                var myArray = new Array();
                for (var i = 0; i < res.data.response.length; i++) {
                myArray.push(res.data.response[i]);
                }
                this.departmentsearchdata = myArray;
                this.$refs.tree1.setCurrentKey(null);
            });
        },
        //刷新当前选择的部门树节点，末级节点刷新父节点
        refreshDptTreeNodeAfterAdd () {
            //当前选中
            var treeid = this.$refs.tree1.getCurrentKey();
            if (treeid) {
                //当前选中节点
                var cunode = this.$refs.tree1.getNode(treeid);
                //叶子节点
                if (cunode.isLeaf) {
                var pdtreeid = cunode.data.ParentId;
                //顶级节点或没有父节点
                if (cunode.level == 1 || pdtreeid == undefined) {
                    //全部重新加载
                    let para = { deptId: null };
                    GetChildrenList().then((res) => {
                        // console.log(res);
                        const initdptdata = res.data.response;
                        this.departmentsearchdata = initdptdata;
                        this.$refs.tree1.setCurrentKey(null);
                    });
                } else {
                    //重新加载当前节点的父节点
                    var pdnode = this.$refs.tree1.getNode(pdtreeid);
                    this.refreshLazyTree(pdnode);
                }
                } else {
                //不是叶子结点，重新加载当前节点的子节点
                this.refreshLazyTree(cunode);
                }
            } else {
                let para = { deptId: null };
                GetChildrenList().then((res) => {
                    // console.log(res);
                    const initdptdata = res.data.response;
                    this.departmentsearchdata = initdptdata;
                    this.$refs.tree1.setCurrentKey(null);
                });
            }
        },
        //懒加载刷新节点
        refreshLazyTree (node) {
            //非末级节点清空子节点
            if (!node.isLeaf) {
                var theChildren = node.childNodes;
                theChildren.splice(0, theChildren.length);
                // node.doCreateChildren(children);
            }
            node.loaded = false;
            node.expand();
        },
        //部门树节点渲染
        renderContent (h, { node, data, store }) {
            var icon = "";
            for (var i = 0; i < this.TypeIdModules.length; i++) {
                var tm = this.TypeIdModules[i];
                if (tm.Id == data.TypeId) {
                icon = tm.Icon;
                break;
                }
            }
            return (
                <span class="custom-tree-node">
                <i class={icon}>{node.label}</i>
                </span>
            )
        },
        selectCurrentRow(val) {
            this.currentRow = val;
        },
        selectDepartUserRow(val){
            this.DepartUserRow = val;
            this.departuserid = val.id;
            this.YearPlanTaskId = null;
            this.getManagerChecks();
            // let para = {userCode: val.id};
            // GetYearPlanListByUserCode(para).then((res) => {
            //     this.YearPlanTaskList = res.data.response;
            // });
        },
        YearPlanchange(val){
            this.YearPlanTask = val.split('|')[2];
            this.YearPlanTaskId = val.split('|')[1];
        },
        load(tree,treeNode,resolve){
            var notaskType=tree.NoTask;
            let para = {
                taskId: tree.TaskId,
                notaskType:notaskType,
            };
            var userCode = this.departuserid;
            QueryChildTasksByParentId(para).then((res) => {
                var data=[];
                var d=res.data.response;
                d.forEach(element => {
                    if(element.CreatedId==userCode)
                    {
                        data.push(element);
                    }else if(element.PersonOfDutyCode==userCode)
                    {
                        data.push(element);
                    }
                });
                resolve(data);
            });
        },
        formatPlanComplateTime: function (row,colume) {
            return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime),'yyyy-MM-dd');
        },
        formatOfYear: function (row,colume) {
            return (!row.OfYear || row.OfYear == '') ? '' : util.formatDate.format(new Date(row.OfYear),'yyyy');
        },
        formatCreatedOn: function (row,colume) {
                return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn),'yyyy-MM-dd hh:mm');
            },
        formatCommonTaskClassifyText: function(row,colume){
                if(row.TaskClassify ==1){  
                    // return row.CommonTaskClassifyText;
                    return "日常工作";
                }else{
                    if(row.TaskClassify ==2)
                    {
                        return "跨部门协调";
                    }
                    else if(row.TaskClassify ==3)
                    {
                        return "例会项目";
                    }else if(row.TaskClassify ==4)
                    {
                        return "年度计划";
                    }else if(row.TaskClassify ==5){
                        return "专项会议";
                    }
                    else {
                        return "其他";
                    }
                }
            },
        formatSource: function (row,column) {
                return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
            },
        formatCreateTime: function(row,column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
            },
        formatTipCreateTime: function (row, column) {
                return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime),'yyyy-MM-dd hh:mm');
            },
        formatProgress:function(row,col)
        {
            return row.TaskProgressValue+"%";
        },
        //翻页处理
        handleCurrentChange(val) {
           this.page = val;
           this.getManagerChecks();
           this.page = 1;
      },
      getManagerChecks(){
           //时间转化
             let begintime = "";  //开始时间
             let endtime = "";    //结束时间
            if(this.madedate != null){
                switch (this.madedate.length) {
                    case 1:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    break;
                    case 2:
                    begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
                    endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
                    break;
                    default:
                    break;
                }    
            }
            if(this.departuserid == null){
                 this.$message({
                    message: '请先选择想要查看任务的部门人员',
                    type: 'warning',
                    duration: 7000
                });
                return;
            }
            let para = {
                year: this.yearvalue,
                userCode: this.departuserid,
                workItem: this.workItem,
                taskClassify: this.taskClassify,
                taskStatus: this.taskStatus,
                begin: begintime,
                end: endtime,
                yearplanId: this.YearPlanTaskId,
                progressValue: this.progressValue,
                pageIndex: this.page,
                pageSize: this.pageSize
            };
            QueryPageYearplanTasksByWhereForJixiaopinggu(para).then((res) => {
                 this.total = res.data.response.pageCount;
                 this.totaldata = res.data.response.dataCount;
                 this.users = res.data.response.data;
            });
      },
      //保存
      addSave()
      {
          let self=this;
          if(self.addForm.Month.length==0)
          {
                this.$message({
                    message: '请选择评估月份',
                    type: 'warning'
                });
                return;
          }
          if(self.addForm.Content.length==0)
          {
                this.$message({
                    message: '请输入评价内容',
                    type: 'warning'
                });
                return;
          }
          if(self.addForm.Id.length==0)
          {
            var taskId=self.singleYearplanTask[0].Id;
            var month=self.addForm.Month;
            var score=self.addForm.Score;
            var description=self.addForm.Content;
            var status = 0;
            var user = JSON.parse(window.localStorage.user);
            var createdid=user.sub;
            var createdby=user.name;
            let para = { TaskId:taskId,Month:month,Score:score,Description:description,Status:status,CreatedId:createdid,CreatedBy:createdby };
            //console.log(para);

            if(self.singleYearplanTask[0].PersonOfDutyCode==para.CreatedId)
            {
                this.$message({
                    message: '本人无需对自己的绩效进行评估',
                    type: 'warning'
                });
                return;
            }
            AddTaskPingfen(para).then((res) => {
                if(res.data.success) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    //this.QueryPingguInfo();
                    this.QueryPingguInfo();
                    this.pingguVisible=false;
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
          }else{
            var Id=self.addForm.Id;
            var score=self.addForm.Score;
            var description=self.addForm.Content;
            var status=0;
            let para = { Id:Id,Score:score,Description:description,Status:status };
            //console.log(para);
            UpdateTaskPingfen(para).then((res) => {
                if(res.data.success) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.QueryPingguInfo();
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });

          }

      },
      //提交
      addSubmit(){
          let self=this;
          if(self.addForm.Month.length==0)
          {
                this.$message({
                    message: '请选择评估月份',
                    type: 'warning'
                });
                return;
          }
          if(self.addForm.Content.length==0)
          {
                this.$message({
                    message: '请输入评价内容',
                    type: 'warning'
                });
                return;
          }
        //   var taskId=self.singleYearplanTask[0].Id;
        //   var month=self.addForm.Month;
        //   var score=self.addForm.Score;
        //   var description=self.addForm.Content;
        //   var status = 1;
        //   var user = JSON.parse(window.localStorage.user);
        //   var createdid=user.sub;
        //   var createdby=user.name;
        this.$confirm('确定要提交吗？', '提示', {}).then(() => {


          if(self.addForm.Id.length==0)
          {
            var taskId=self.singleYearplanTask[0].Id;
            var month=self.addForm.Month;
            var score=self.addForm.Score;
            var description=self.addForm.Content;
            var status = 1;
            var user = JSON.parse(window.localStorage.user);
            var createdid=user.sub;
            var createdby=user.name;
            let para = { TaskId:taskId,Month:month,Score:score,Description:description,Status:status,CreatedId:createdid,CreatedBy:createdby };
            // console.log(para);

            if(self.singleYearplanTask[0].PersonOfDutyCode==para.CreatedId)
            {
                this.$message({
                    message: '本人无需对自己的绩效进行评估',
                    type: 'warning'
                });
                return;
            }
            AddTaskPingfen(para).then((res) => {
                if(res.data.success) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.QueryPingguInfo();
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });
          }else{
            var Id=self.addForm.Id;
            var score=self.addForm.Score;
            var description=self.addForm.Content;
            var status=1;
            let para = { Id:Id,Score:score,Description:description,Status:status };
            //console.log(para);
            UpdateTaskPingfen(para).then((res) => {
                if(res.data.success) {
                    this.$message({
                        message: res.data.msg,
                        type: 'success'
                    });
                    this.QueryPingguInfo();
                }else{
                    this.$message({
                        message: res.data.msg,
                        type: 'error'
                    });
                }
            });

          }
        });
      },
      QueryMyPingfenList(row){
        let self=this;
        self.mypingguListVisible = true;
        // console.log(row);
        var taskId=row.Id;
        QueryTaskPingfenByTaskId({taskId:taskId}).then(res=>{
            var data=res.data.response;
            self.myPingfenList = data;
            // console.log(data);
        });
      },
      QueryPingguInfo()
      {
        let self=this;
        var taskId=self.singleYearplanTask[0].Id;
        var month=self.addForm.Month;
        QueryTaskPingfenByTaskId({taskId:taskId,month:month}).then(res=>{
            //console.log(res)
            var data=res.data.response;
            //console.log(data)
            if(data.length>0)
            {
                self.addForm.Id=data[0].Id;
                self.addForm.Score=data[0].Score;
                // self.addForm.Content=data[0].Description;
                self.addForm.Status=data[0].Status;
                var status=data[0].Status;
                if(status==0)
                {
                    self.alerts.push(data[0].Description)
                    self.showSaveBtn=true;
                    self.showSubmitBtn=true;
                }else if(status==1){
                    self.showSaveBtn=false;
                    self.showSubmitBtn=false;
                    self.addForm.Content=data[0].Description;
                }
            }else{
                self.addForm.Id='';
                self.addForm.Score=self.singleYearplanTask[0].Rate;
                // self.addForm.Content='';
                self.addForm.Status=-1;
                self.showSaveBtn=true;
                self.showSubmitBtn=true;
                self.alerts = []
            }
            // alert(data.length);
            // this.aboutTaskList = data;
        });
        this.calcPersonRate();

      },
      pingguclose()
      {
          let self=this;
          self.addForm.Id='';
          self.addForm.Content='';
          self.addForm.Score=0;
          self.addForm.Month='';
          self.showSaveBtn=true;
          self.showSubmitBtn=true;
      },
      openPinggu(row){
        // console.log(row);
        this.pingguVisible = true;
        this.singleYearplanTask=new Array();
        this.singleYearplanTask.push(row);
        this.addForm.Score=row.Rate;

        var yearplantaskid=row.Id;
        QueryYearPlanAboutTaskByYearplanId({yearplantaskid:yearplantaskid,taskclassify:0,month:'',month2:'',querySelf:true}).then(res=>{
            var data=res.data.response.data;
            // alert(data.length);
            this.aboutTaskList = data;
        });
        setTimeout(() => {
            
            var now=new Date();
            var year=now.getFullYear();
            var month=Number(now.getMonth());
            var ling='';
            if(month < 10)
            {
                ling="0";
            }
            month=month+1;
            this.addForm.Month=year+'-'+ling+month;
            this.QueryPingguInfo();
        }, 500);
      },
      handlePingguMonthChange(val)
      {
        //   console.log(val);
          this.QueryPingguInfo();

      },
      checkclose () {
          this.checkVisible = false;
      },
       //双击查看详细信息
      checkInfo(row){
        if(row.NoTask>0)
        {
            return;
        }
        this.checkVisible = true;
        this.checkForm = row;
        return;
        if(row.TaskClassify ==1){  
            // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
            this.CommonTaskClassifyText = "日常工作";
                this.showorganize = false;
                this.showyearplan = false;
                this.showregularproject = false;
                this.showprofessionmeetong = false;
                if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                }else{
                   this.showAssign = false; 
                }
                this.createPerson = true;
        }else{
                if(row.TaskClassify ==2)
                {
                    this.CommonTaskClassifyText = "跨部门协调";
                    this.showorganize = true;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
                else if(row.TaskClassify ==3)
                {
                    this.CommonTaskClassifyText =  "例会项目";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = true;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }else if(row.TaskClassify ==4)
                {
                    this.CommonTaskClassifyText =  "年度计划";
                    this.showorganize = false;
                    this.showyearplan = true;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }else if(row.TaskClassify ==5){
                    this.CommonTaskClassifyText = "专项会议";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = true;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = true;
                }
                else {
                    this.CommonTaskClassifyText =  "其他";
                    this.showorganize = false;
                    this.showyearplan = false;
                    this.showregularproject = false;
                    this.showprofessionmeetong = false;
                    if(row.PersonOfDuty != row.CreatedBy){
                    this.showAssign = true;
                    }else{
                    this.showAssign = false; 
                    }
                    this.createPerson = false;
                }
            }
            QueryTasksById({Id: row.Id,taskClassify: row.TaskClassify}).then((res) => {
                this.checkForm = res.data.response;
                this.checkForm.Files=[];
                if(res.data.response.FileName!=null)
                {
                    var arr=res.data.response.FileName.split(",");
                    var arr2=res.data.response.FileAddress.split(",");
                    var files=[];
                    for(var a=0;a<arr.length;a++)
                    {
                        var f={};
                        f.FileName=arr[a];
                        f.FileAddress=arr2[a];
                        files.push(f);
                    }
                    this.checkForm.Files=files;
                }
            })
          this.pValue1 = row.TaskProgressValue;
        //   this.checkForm = Object.assign({},row);
          let para = {taskId: row.Id};
          QueryTaskProgressByTaskId(para).then((res) => {
            //   this.tasks = res.data.response;
                var data =res.data.response;
                let formatData=new Array();
                for(var a=0;a<data.length;a++)
                {
                        var element = data[a];
                        element.Files=[];
                        if(element.FileName!=null)
                        {
                            var arr=element.FileName.split(",");
                            var arr2=element.FileAddress.split(",");
                            var files=[];
                            for(var d=0;d<arr.length;d++)
                            {
                                var f={};
                                f.FileName=arr[d];
                                f.FileAddress=arr2[d];
                                files.push(f);
                            }
                            element.Files=files;
                        }
                        formatData.push(element);
                }
                this.tasks = formatData;
          });
        },
        checkclose2 () {
            this.lookOneAboutTaskVisible = false;
        },
        checkAboutInfo(row){
            var ucode = JSON.parse(window.localStorage.user);
            this.lookOneAboutTaskVisible = true;
            this.checkForm = row;
            return;
            if(row.WorkType == 1){
                this.WorkItemType = "日常"
            }else{
                this.WorkItemType= "项目"
            }
            this.CommonTaskClassifyText=row.CommonTaskClassifyText;
            // console.log('row');
            // console.log(row);
            // console.log(this.CommonTaskClassifyText);
            this.pValue1 = row.TaskProgressValue;
            this.pValue2 = row.TaskProgressValue;
            this.progresscheck = row.ChildrenProgressComplated;
            if(row.PersonOfDuty != ucode.name || row.Status != 0){
              this.isyearplanpro = false;
            }else{
                if(this.pValue1 < 100){
                  if(this.pValue1 == 95 && this.progresscheck == false){
                      this.isyearplanpro = false;
                  }else{
                      this.isyearplanpro = true;
                  }  
              }else{
                  this.isyearplanpro = false;
              }
            }
            GetClassifyList().then((res) => {
                this.CommonTaskClassifyList = res.data.response;
            });
            this.checkForm = Object.assign({},row);
            let para = {taskId: row.Id};
            QueryTaskProgressByTaskId(para).then((res) => {
                // this.tasks = res.data.response;
                var data =res.data.response;
                let formatData=new Array();
                for(var a=0;a<data.length;a++)
                {
                        var element = data[a];
                        element.Files=[];
                        if(element.FileName!=null)
                        {
                            var arr=element.FileName.split(",");
                            var arr2=element.FileAddress.split(",");
                            var files=[];
                            for(var d=0;d<arr.length;d++)
                            {
                                var f={};
                                f.FileName=arr[d];
                                f.FileAddress=arr2[d];
                                files.push(f);
                            }
                            element.Files=files;
                        }
                        formatData.push(element);
                }
                this.tasks = formatData;
            });
        },
        cascaderchange(node){
            if(this.$refs.department.getCheckedNodes().length>0)
            {
                let pathvalue = this.$refs.department.getCheckedNodes()[0];
                this.checkdepartid = pathvalue.value;
                this.getDepartUsers(pathvalue.value);       
            }
        },
        handleCurrentChangeDepart(val){
           if(this.checkdepartid==null)
           {
               return;
           }
           this.Departpage = val;
           this.getDepartUsers(this.checkdepartid);
           this.Departpage = 1;
        },
        //获取部门人员
        getDepartUsers(node){
            let para = {
                deptId: node,
                pageNum: this.Departpage,
                // size: this.DepartpageSize
                isAllNextDept:false,
                isMainJob:true,
                pageSize: 200
            }
            GetDepartmentPostUserList(para).then(res => {
                var data=res.data.data.list;
                let newdata=[];
                if(this.checked1)
                {
                    data.forEach(element => {
                        if(element.workTypeName=='一线')
                        {
                            newdata.push(element);
                        }
                    });
                }
                if(this.checked2)
                {
                    data.forEach(element => {
                        //console.log(element)
                        if(element.workTypeName=='后勤')
                        {
                            newdata.push(element);
                        }
                    });
                }
                
                // newdata=this.unique(data);

                // console.log(newdata.length);

                this.departusersfull = newdata;
                this.departusers = newdata;
                // this.Departtotal = res.data.response.pageCount;
                // this.Departtotaldata = res.data.response.dataCount;
                // this.Departtotal = 1;
                // this.Departtotaldata = 1000;
            });
        },
         tableRowClassName({row, rowIndex}){
            // if (rowIndex % 2 !== 0) {
            // return 'warning-row';
            // }
            // return '';    
            if(row.CurrentMonthHasPingfen)
            {
                return 'success-row';
            }else{
                return '';
            }
       },
        unique(arr) {
            const res = new Map();
            return arr.filter((arr) => !res.has(arr.deptId) && res.set(arr.deptId, 1));
        },
        GetRemoteConfigValue(){
            let self = this;
            GetRemoteConfigValue({key:'pingfenDayOfMonthMax'}).then((res)=>{
                // console.log(res);
                if(res.status==200){
                    var maxDay = res.data.response;
                    self.maxDay = maxDay;
                    var date = new Date();
                    var d = date.getDate();
                    console.log(d+","+maxDay)
                    if(d>Number(maxDay)){//超过当月某一天自动锁定
                        this.disabledSave = true;
                        this.disabledSubmit = true;
                    }else{
                        this.disabledSave = false;
                        this.disabledSubmit = false;
                    }
                }
            })
        }
    },
    mounted(){
        // GetDepartmentTypes().then((res) => {
        //     this.TypeIdModules = res.data.response.data;
        //  });
        // var user = JSON.parse(window.localStorage.user);
        // this.userid = user.sub;
        // let para = {userId: user.sub};
        
    }
}
</script>
<style scoped>
.orgtree {
  /* overflow-y: auto;
  overflow-x: scroll; */
  height: calc(100vh - 280px);
  width:200px;
  border: 0px solid blue;
  padding-top: 65px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
  
}
/deep/.el-table__body tr.current-row>td {
  background: #FABE64 !important;
}
/deep/input:disabled,/deep/textarea:disabled{
    opacity: 1;
    -webkit-text-fill-color: rgb(12, 12, 12);
}
.managercheckdiv {
 /* height: 600px; */
  height: calc(100vh - 120px);
}
#managercheckcard{
    /* height: 600px; */
  height: calc(100vh - 120px);
}
.tasksdiv {
    /* max-height: 400px;
    overflow: auto; */
  max-height: calc(100vh - 230px);
  overflow: auto;
  padding-left: 2px;
}
/deep/.el-table .warning-row {
    background: #F0F8FF;
}
/deep/.el-button--goon {
  color: #FFF;
  background-color: #20B2AA;
  border-color: #20B2AA;
}
/deep/.el-button--goon:hover {
  background: #48D1CC;
  border-color: #48D1CC;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20B2AA;
  border-color: #20B2AA;
  color: #fff;
}
/deep/span.el-range-separator{
    width: 20px;
}

</style>